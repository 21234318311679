<template>
  <form style="text-align: right;" method="GET" ref="MPTokForm"></form>
</template>
<script>
export default {
  name: "CheckoutStep2Payment",
  props: ["url", "preference_id"],
  mounted() {
    let scriptEl = document.createElement("script");
    scriptEl.setAttribute(
      "src",
      "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js"
    );
    scriptEl.setAttribute("data-preference-id", this.preference_id);
    scriptEl.setAttribute("data-button-label", "Continuar");
    this.$refs.MPTokForm.appendChild(scriptEl);
  }
};
</script>

<style>
.mercadopago-button {
  text-align: right;
  background-color: #f68d1e !important;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
}
</style>
