<template>
  <div style=" display: contents;">
    {{ seconds }}
  </div>
</template>
 

<script>
export default {
  name: "Crontab",
  props: {
    time: {
      default: 30,
    },
  },
  data() {
    return {
      seconds: null,
      interval: null,
    };
  },
  mounted() {
    this.seconds = this.time;
    this.interval = setInterval(() => {
      this.seconds--;
      if (this.seconds == 0) {
        this.seconds = this.time;
        this.$emit("onUpdate");
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script> 