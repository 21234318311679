<template>
  <b-card>
    <loading :active.sync="isLoading" :full-poge="false"></loading>
    <div class="p-4">
      <div class="card-body pt-0" v-if="closure">
        <b-alert show dismissible v-if="closure.status == 'POR FACTURAR'">
          Pudimos procesar tu pago correctamente, tu operaciones seran procesada
          pronto.
          <hr />
          <p class="mb-0">
            <router-link to="/dashboard">
              <b>&rArr;</b> haz click aqui para continuar
            </router-link>
          </p>
        </b-alert>

        <b-row>
          <b-col size="12">
            <div v-if="outstanding" class="mb-2">
              La información de facturación sera actualizada en de
              <crontab @onUpdate="onUpdateCrontab" />
              segundos.
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <RowComponent
              :title="'DNI:'"
              :content="
                closure.billing.type_document + ' ' + closure.billing.dni
              "
            />
            <RowComponent
              :title="'NOMBRE APELLIDOS:'"
              :content="closure.billing.name + ' ' + closure.billing.last_name"
            />
            <RowComponent
              :title="'TELEFONO:'"
              :content="closure.billing.phone"
            />
            <RowComponent
              :title="'CONDICION IVA: '"
              :content="closure.billing.vat_condition"
            />
            <RowComponent
              :title="'TIPO DE COMPROBANTE: '"
              :content="closure.billing.voucher_type"
            />
          </b-col>
          <b-col>
            <RowComponent
              :title="'PROVINCIA:'"
              :content="closure.billing.direction.location.province.province"
            />
            <RowComponent
              :title="'LOCALIDAD:'"
              :content="closure.billing.direction.location.location"
            />
            <RowComponent
              :title="'CALLE:'"
              :content="closure.billing.direction.street"
            />
            <RowComponent
              :title="'ALTURA:'"
              :content="closure.billing.direction.height"
            />
            <RowComponent
              :title="'CODIGO POSTAL:'"
              :content="closure.billing.direction.postal_code"
            />
            <RowComponent
              :title="'NOTE:'"
              :content="closure.billing.direction.note"
            />
          </b-col>
        </b-row>

      
        <div v-if="closure.transactions.length > 0" class="table-responsive">

          <h3 class="title">OPERACIONES</h3>

          <table class="table table-bordered table-sm text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>Cliente</th>
                <th>Email</th>
                <th>Producto</th>
                <th>Pago</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in closure.transactions" v-bind:key="item.id">
                <td>{{ item.id }}</td>
                <td>
                  {{ item.recipient_name }} {{ item.recipient_last_name }}
                </td>
                <td>{{ item.recipient_email }}</td>
                <td>{{ item.product }}</td>
                <td>{{ item.status_payment }}</td>
                <td>{{ item.amount | currency }}</td>
              </tr>

              <tr>
                <td>---------</td>
                <td>---------</td>
                <td>---------</td>
                <td>Cargo por Impuestos</td>
                <td>---------</td>
                <td>{{ taxes | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>


        <div v-if="closure.transactions_charge.length > 0" class="table-responsive">

          <h3 class="title">CARGOS EXTRA</h3>

          <table class="table table-bordered table-sm text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>Titulo</th>
                <th>Detalle</th>
                <th>Pago</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in closure.transactions_charge" v-bind:key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.body }}</td>
                <td>{{ item.status_payment }}</td>
                <td>{{ item.price | currency }}</td>
              </tr>

            </tbody>
          </table>
        </div>






        <p class="text-right mt-3">
          <span>Total :</span>
          <b>{{ closure.total | currency }}</b>
        </p>
        <div v-if="!outstanding && closure.status === 'PENDIENTE'">
          <checkout-step2-payment
            :preference_id="closure.billing.preference_id"
            :total="closure.total"
          />
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import MercadoPagoService from "@/services/MercadoPagoService";
import CheckoutStep2Payment from "./components/CheckoutStep2Payment";
import Crontab from "./components/Crontab";
import moment from "moment";

export default {
  name: "OperationPay",
  components: { CheckoutStep2Payment, Crontab },
  data() {
    return {
      closure: null,
      isLoading: false,
      outstanding: null,
    };
  },
  computed: {
    taxes: function () {
      const trans = this.closure.transactions
        .map((d) => d.amount)
        .reduce((b, a) => b + a, 0);
      return this.closure.total - trans;
    },
  },
  methods: {
    onUpdateCrontab() {
      this.show();
    },
    /**
     *
     */
    toDateFecha(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    show() {
      this.isLoading = true;
      MercadoPagoService.show(this.$route.params.id)
        .then((response) => {
          const { data } = response;
          this.isLoading = false;
          this.closure = data.data;
          if (this.closure.status == "APPROVED") {
            this.$swal
              .fire({
                icon: "success",
                title: "Pago procesado.",
                showConfirmButton: true,
                timer: 2000,
              })
              .then(() => this.$router.push("/dashboard/operations"));
          }
        })
        .catch((thrown) => {
          this.isLoading = false;
          this.error = true;
          console.error(thrown);
        });
    },
  },
  mounted() {
    this.outstanding = this.$route.query.status;
    this.show();
  },
};
</script>

<style></style>
